<template>
    <div class="flex flex-row w-full">
        <LoadingIcon :loading="loading" class="p-20" />
        <!-- If mobile then w-1/5 and only display icons -->
        <div v-if="!loading" class="w-1/5 md:w-1/4 fixed left-0 top-0 bg-background2 h-screen shadow-md z-50">
            <div class="flex flex-col items-center md:items-start w-full justify-start h-full">
                <router-link to="/"
                    class="flex items-center justify-center md:ml-3 h-16 px-2 md:px-4 p-1 md:p-0 mr-0 md:mr-3 hover:bg-accent rounded-lg">
                    <img src="@/assets/images/logo.png" alt="logo" class="h-9 w-9 rounded-lg" />
                    <div class="text-2xl font-semibold text-tertiary ml-3 hidden md:block">
                        {{ $t('navbar.title') }}
                    </div>
                </router-link>
                <router-link to="/dashboard" class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent"
                    @click="switch_selected('dashboard')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'dashboard' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/home.svg" alt="home" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'dashboard' }">
                            {{ $t('navbar.dashboard') }}
                        </div>
                    </div>
                </router-link>
                <router-link v-if="role == 'cleaner'" to="/schedule"
                    class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent" @click="switch_selected('schedule')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'schedule' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/calendar.svg" alt="calender" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'schedule' }">
                            {{ $t('navbar.schedule') }}
                        </div>
                    </div>
                </router-link>
                <router-link to="/chat" class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent"
                    @click="switch_selected('chat')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'chat' }">
                    <div class=" flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/chat.svg" alt="chat" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'chat' }">
                            {{ $t('navbar.chat') }}
                        </div>
                    </div>
                </router-link>
                <router-link v-if="role == 'cleaner'" to="/earnings"
                    class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent" @click="switch_selected('earnings')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'earnings' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/dollar_filled.svg" alt="dollar" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'earnings' }">
                            {{ $t('navbar.earnings') }}
                        </div>
                    </div>
                </router-link>
                <router-link v-if="role == 'user'" to="/booknow"
                    class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent" @click="switch_selected('booknow')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'booknow' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/bookings.svg" alt="dollar" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'booknow' }">
                            {{ $t('navbar.booknow') }}
                        </div>
                    </div>
                </router-link>
                <router-link v-if="role == 'user'" to="/addresses"
                    class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent" @click="switch_selected('addresses')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'addresses' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/location.svg" alt="dollar" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'addresses' }">
                            {{ $t('navbar.my_addresses') }}
                        </div>
                    </div>
                </router-link>
                <router-link v-if="role == 'user' || role == 'admin'" to="/checklists"
                    class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent" @click="switch_selected('checklists')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'checklists' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/checklist.svg" alt="dollar" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'checklists' }">
                            {{ $t('navbar.checklists') }}
                        </div>
                    </div>
                </router-link>
                <router-link v-if="role == 'user'" to="/payment_methods"
                    class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent"
                    @click="switch_selected('payment_methods')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'payment_methods' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/bank.svg" alt="dollar" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'payment_methods' }">
                            {{ $t('navbar.payment_methods') }}
                        </div>
                    </div>
                </router-link>
                <router-link v-if="role == 'admin'" to="/table"
                    class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent" @click="switch_selected('table')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'table' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/dollar_filled.svg" alt="dollar" class="h-6 w-6" />
                        </div>
                        <div class="text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'table' }">
                            {{ $t('navbar.table') }}
                        </div>
                    </div>
                </router-link>
                <router-link to="/settings" class="mt-3 w-full md:pl-9 text-gray-700 p-3 hover:bg-accent"
                    @click="switch_selected('additional_settings')"
                    :class="{ 'text-tertiary bg-accent md:bg-transparent': selected === 'additional_settings' }">
                    <div class="flex items-center justify-center md:justify-start">
                        <div class="mr-0 md:mr-3">
                            <img src="@/assets/icons/settings.svg" alt="settings" class="h-6 w-6" />
                        </div>
                        <div class="flex items-center text-lg font-medium hidden md:block"
                            :class="{ 'font-medium': selected === 'additional_settings' }">
                            <span class="hidden md:block lg:hidden">{{ $t('navbar.settings') }}</span>
                            <span class="hidden lg:block mr-1">
                                {{ $t('navbar.additional') }}
                                {{ $t('navbar.settings') }}
                            </span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div v-if="!loading"
            class="fixed w-4/5 md:w-3/4 right-0 top-0 flex flex-wrap flex-row justify-between md:items-center md:space-x-4 py-2 px-2 top-0 z-40 animated shadow-lg bg-white h-16">
            <div class="flex items-center">
                -
            </div>
            <div class="flex items-center">
                <router-link to="/support" class="mr-4 p-1 hover:bg-accent rounded-lg">
                    <img src="@/assets/icons/support.svg" alt="support" class="h-6 w-6 md:h-7 md:w-7" />
                </router-link>
                <div class="mr-4 p-1 hover:bg-accent rounded-lg"
                    @click.stop="notificationMenuVisible = !notificationMenuVisible">
                    <img src="@/assets/icons/bell.svg" alt="bell" class="h-5 w-5 md:h-6 md:w-6" />
                </div>
                <div ref="notificationMenu" class="w-0">
                    <NotificationComp :visible="notificationMenuVisible" />
                </div>
                <div class="mr-2 p-1 hover:bg-accent rounded-lg" @click.stop="menuVisible = !menuVisible">
                    <img src="@/assets/icons/avatar.png" alt="profile" class="h-7 w-7 md:h-10 md:w-10" />
                </div>
                <!-- Dropdown menu having four options -->
                <div v-if="menuVisible" ref="dropdown"
                    class="absolute top-12 right-2 bg-background shadow-lg rounded-lg p-2 mr-5 z-50">
                    <div class="flex flex-col">
                        <router-link to="/profile"
                            class="py-2 px-2 hover:bg-accent rounded-sm flex flex-row items-center cursor-pointer">
                            <!-- <img src="./assets/images/avatar.svg" alt="Exit" class="w-6 h-6 mr-4" /> -->
                            <span> {{ $t('navbar.profile') }}</span>
                        </router-link>
                        <div @click.stop="logout"
                            class="py-2 px-2 hover:bg-accent rounded-sm flex flex-row items-center cursor-pointer">
                            <!-- <img src="./assets/images/exit.svg" alt="Exit" class="w-6 h-6 mr-4" /> -->
                            <span> {{ $t('navbar.logout') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import NotificationComp from "@/components/NotificationComp.vue";
import { useAuth } from "@/composables/useAuth";
import { useAuthStore } from '@/store/auth'

export default {
    name: 'LeftNavbar',
    components: {
        LoadingIcon,
        NotificationComp,
    },
    setup () {
        const authStore = useAuthStore();
        const role = ref<string>(authStore.user.role);
        const { logout } = useAuth();
        const loading = ref(true);
        const notificationMenuVisible = ref(false);
        const menuVisible = ref(false);
        const selected = ref('dashboard');
        const dropdown = ref<HTMLElement | null>(null);
        const notificationMenu = ref<HTMLElement | null>(null);

        const switch_selected = (newValue: string) => {
            selected.value = newValue;
        };

        const hideMenu = (event: MouseEvent) => {
            try {
                // If menu is visible and clicked outside of menu using refs
                if (menuVisible.value && dropdown.value && !event.composedPath().includes(dropdown.value)) {
                    menuVisible.value = false;
                }
                // Do similar check with notification
                if (notificationMenuVisible.value && notificationMenu.value && !event.composedPath().includes(notificationMenu.value)) {
                    notificationMenuVisible.value = false;
                }
            } catch (err) {
                console.log("Caught Error in hideMenu", err);
            }
        };

        onMounted(() => {
            loading.value = false;
            window.addEventListener('click', hideMenu);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('click', hideMenu);
        });

        return {
            selected,
            loading,
            menuVisible,
            notificationMenuVisible,
            dropdown,
            notificationMenu,
            role,
            logout,
            switch_selected,
            hideMenu,
        };
    },
}
</script>
