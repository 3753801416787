import { parsePhoneNumberFromString } from 'libphonenumber-js';

const validateEmail = (email: string, t: (key: string) => string): string | null => {
  if (
    !email ||
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
  ) {
    return t('validation.invalidEmail');
  }
  return null;
}

const validatePassword = (password: string, t: (key: string) => string): string | null => {
  if (!password || password.length < 8) {
    return t('validation.passwordTooShort');
  }
  return null;
}

const shouldNotEmpty = (str: string, field: string = 'Name', t: (key: string, params?: Record<string, any>) => string): string | null => {
  if (!str) {
    return t('validation.shouldNotBeEmpty', { field });
  }
  return null;
}

const validatePhone = (phone: string, t: (key: string) => string): string | null => {
  if (!phone) {
    return t('validation.phoneRequired');
  }

  try {
    const phoneNumber = parsePhoneNumberFromString(phone);

    if (!phoneNumber) {
      return t('validation.invalidPhoneNumberFormat');
    }

    if (!phoneNumber.isValid()) {
      return t('validation.invalidPhoneNumber');
    }

    return null;
  } catch (error) {
    return t('validation.invalidPhoneNumber');
  }
}

export { validateEmail, validatePassword, shouldNotEmpty, validatePhone }
