class User {
    uid: string;
    role: string;
    name: string;
    email: string;
    phone: string;
    idToken?: string;
    latitude?: string;
    longitude?: string;
    googlePlaceId?: string;
    unreadMessagesAdmin: number = 0;
    unreadMessages: number = 0;
    description?: string;
    distance: number = 2;
    rating: number = 5.0;
    stripeCustomerId?: string;
    stripeAccountId?: string;
  
    constructor({
      uid,
      role,
      name,
      email,
      phone,
      idToken,
      latitude,
      longitude,
      googlePlaceId,
      unreadMessagesAdmin = 0,
      unreadMessages = 0,
      distance = 2,
      description = '',
      rating = 5.0,
      stripeCustomerId,
      stripeAccountId,
    }: {
      uid: string;
      role: string;
      name: string;
      email: string;
      phone: string;
      idToken?: string;
      latitude?: string;
      longitude?: string;
      googlePlaceId?: string;
      unreadMessagesAdmin?: number;
      unreadMessages?: number;
      distance?: number;
      description?: string;
      rating?: number;
      stripeCustomerId?: string;
      stripeAccountId?: string;
    }) {
      this.uid = uid;
      this.role = role;
      this.name = name;
      this.email = email;
      this.phone = phone;
      this.idToken = idToken;
      this.latitude = latitude;
      this.longitude = longitude;
      this.googlePlaceId = googlePlaceId;
      this.unreadMessagesAdmin = unreadMessagesAdmin;
      this.unreadMessages = unreadMessages;
      this.distance = distance;
      this.description = description;
      this.rating = rating;
      this.stripeCustomerId = stripeCustomerId;
      this.stripeAccountId = stripeAccountId;
    }
  
    static fromMap(json: { [key: string]: any }): User {
      return new User({
        uid: json['uid'],
        role: json['role'],
        name: json['name'],
        email: json['email'],
        phone: json['phone'],
        idToken: json['idToken'],
        latitude: json['latitude'],
        longitude: json['longitude'],
        googlePlaceId: json['googlePlaceId'],
        unreadMessagesAdmin: json['unreadMessagesAdmin'],
        unreadMessages: json['unreadMessages'],
        distance: json['distance'],
        description: json['description'],
        rating: json['rating'],
        stripeCustomerId: json['stripeCustomerId'],
        stripeAccountId: json['stripeAccountId'],
      });
    }
  
    toMap(): { [key: string]: any } {
      return {
        uid: this.uid,
        role: this.role,
        name: this.name,
        email: this.email,
        phone: this.phone,
        idToken: this.idToken,
        latitude: this.latitude,
        longitude: this.longitude,
        googlePlaceId: this.googlePlaceId,
        unreadMessagesAdmin: this.unreadMessagesAdmin,
        unreadMessages: this.unreadMessages,
        distance: this.distance,
        description: this.description,
        rating: this.rating,
        stripeCustomerId: this.stripeCustomerId,
        stripeAccountId: this.stripeAccountId,
      };
    }
}

export default User;
