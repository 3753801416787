import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import {
  firestore,
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail
} from '@/services/firebase'
import {
  User as FirebaseUser,
  updateProfile,
  sendEmailVerification
} from 'firebase/auth'
import {
  validateEmail,
  validatePassword,
  shouldNotEmpty,
  handleError,
  validatePhone,
  getUserProfile,
  getUserData
} from '@/utils'
import { useI18n } from 'vue-i18n'
import User from '@/models/User'
import { useAuthStore } from '@/store/auth'
import { collection, setDoc, doc, serverTimestamp } from "firebase/firestore";

export function useAuth () {
  const { t } = useI18n({})

  const user = ref<User | null>(null)
  const error = ref<string | null>(null)
  const success = ref<string | null>(null)
  const loading = ref<boolean>(false)
  const router = useRouter()
  const authStore = useAuthStore()

  async function validates (email: string, password: string) {
    const emailError = validateEmail(email, t)
    if (emailError) {
      error.value = emailError
      return false
    }
    const passwordError = validatePassword(password, t)
    if (passwordError) {
      error.value = passwordError
      return false
    }
    return true
  }

  async function login (email: string, password: string) {
    if (!(await validates(email, password))) {
      return
    }
    try {
      loading.value = true
      error.value = null
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user: FirebaseUser = userCredential.user
      // if emailVerified is false, send verification email.
      if (!user.emailVerified) {
        console.log('sendEmailVerification')
        await sendEmailVerification(user)
        error.value = t('errors.auth.emailNotVerified')
        await auth.signOut()
      } else {
        success.value = t('success.auth.loginSuccess')
        const updatedUser = await getUserData(user)
        authStore.setUser(updatedUser)
        setTimeout(() => {
          router.push('/')
        }, 2000)
      }
    } catch (err) {
      error.value = await handleError(err)
      error.value = t(`errors.auth.${error.value}`)
    } finally {
      loading.value = false
    }
  }

  async function signup (
    name: string,
    email: string,
    phone: string,
    password: string,
    paramTypes: string | null
  ) {
    if (!(await validates(email, password))) {
      return
    }
    if (shouldNotEmpty(name, 'Name', t)) {
      error.value = shouldNotEmpty(name, 'Name', t)
      return
    }

    if (validatePhone(phone, t)) {
      error.value = validatePhone(phone, t)
      return
    }

    try {
      loading.value = true
      error.value = null
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser)
        await updateProfile(auth.currentUser, {
          displayName: name
        })
        // send get request to https://api.ipify.org/?format=json
        // get ip address from response.
        const request = await fetch('https://api.ipify.org/?format=json')
        const response = await request.json()
        const ip = response.ip
        // store on firestore.
        const user = auth.currentUser
        const userDocRef = doc(firestore, 'users', user.uid);
        const userData = {
          uid: user.uid,
          name: name,
          email: email,
          phone: phone,
          role: paramTypes ?? 'user',
          createdAt: serverTimestamp(),
          ip: ip,
        }
        await setDoc(userDocRef, userData);
      } else {
        error.value = t('errors.auth.signupFailed')
      }
      success.value = t('success.auth.signupSuccess')
      await auth.signOut()
      setTimeout(() => {
        router.push('/auth/login')
      }, 5000)
    } catch (err) {
      error.value = await handleError(err)
      error.value = t(`errors.auth.${error.value}`)
    } finally {
      loading.value = false
    }
  }

  async function resetPassword (email: string) {
    if (validateEmail(email, t)) {
      error.value = validateEmail(email, t)
      return
    }
    try {
      loading.value = true
      error.value = null
      await sendPasswordResetEmail(auth, email)
      success.value = t('success.auth.resetPassword')
    } catch (err) {
      error.value = await handleError(err)
    } finally {
      loading.value = false
    }
  }

  const logout = async () => {
    await auth.signOut()
    authStore.clearUser()
    router.push('/auth/login')
  }

  return {
    user,
    error,
    success,
    loading,
    login,
    signup,
    resetPassword,
    logout
  }
}
