import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  DocumentReference,
  DocumentData
} from 'firebase/firestore'
import { firestore } from '@/services/firebase'
import UserType from '@/types/user'
import User from '@/models/User'
import { User as FirebaseUser, IdTokenResult}  from 'firebase/auth'

const getUserProfile = async (uid: string): Promise<UserType | null> => {
  const userRef: DocumentReference<DocumentData> = doc(firestore, 'users', uid)
  const userDoc = await getDoc(userRef)

  if (userDoc.exists()) {
    return userDoc.data() as UserType
  }
  return null
}

const updateLastLogin = async (uid: string): Promise<void> => {
  const userRef: DocumentReference<DocumentData> = doc(firestore, 'users', uid)
  await updateDoc(userRef, {
    lastLogin: serverTimestamp()
  })
}

// Update FCM token for a user
const updateFCMToken = async (uid: string, fcmToken: string): Promise<void> => {
  const userRef: DocumentReference<DocumentData> = doc(firestore, 'users', uid)
  await updateDoc(userRef, {
    web_fcmtoken: fcmToken
  })
}

const getUserData = async (user: FirebaseUser): Promise<User> => {
  // get custom claims.
  const idTokenResult: IdTokenResult = await user.getIdTokenResult()
  // get user role.
  const role: string = (idTokenResult.claims.role as string) ?? 'user'
  const currentUser: FirebaseUser | null = user
  if (!currentUser) {
    throw new Error('No authenticated user found')
  }
  const userProfile: UserType | null = await getUserProfile(currentUser.uid)
  if (!userProfile) {
    throw new Error('User profile not found')
  }
  const email: string = currentUser.email ?? ''
  const latitude: string =
    (typeof userProfile.latitude === 'number'
      ? userProfile.latitude.toString()
      : userProfile.latitude) ?? '0'

  const longitude: string =
    (typeof userProfile.longitude === 'number'
      ? userProfile.longitude.toString()
      : userProfile.longitude) ?? '0'

  const updatedUser: User = new User({
    uid: currentUser.uid,
    role: role,
    name: userProfile.name,
    email: email,
    phone: userProfile.phone,
    latitude: latitude ?? 0,
    longitude: longitude ?? 0,
    googlePlaceId: userProfile.googlePlaceId ?? '',
    unreadMessagesAdmin: userProfile.unreadMessagesAdmin ?? 0,
    unreadMessages: userProfile.unreadMessages ?? 0,
    distance: userProfile.distance ?? 2,
    description: userProfile.description ?? '',
    rating: userProfile.rating ?? 5.0,
    stripeAccountId: userProfile.stripeAccountId ?? '',
    stripeCustomerId: userProfile.stripeCustomerId ?? '',
  })
  return updatedUser
}

export { getUserProfile, updateLastLogin, updateFCMToken, getUserData }
