import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/Auth/LoginView.vue')
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import('../views/Auth/RegisterView.vue')
  },
  {
    path: '/auth/password-reset',
    name: 'password-reset',
    component: () => import('../views/Auth/PasswordReset.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard/DashboardView.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/Order/OrderView.vue'),
    meta: {
      title: 'Order',
      requiresAuth: true
    }
  },
  {
    path: '/support',
    name: 'support',
    component: () => import('../views/Chat/SupportScreen.vue'),
    meta: {
      title: 'Support',
      requiresAuth: true
    }
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => import('../views/Chat/ChatScreen.vue'),
    meta: {
      title: 'Chat',
      requiresAuth: true
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: () => import('../views/Schedule/ScheduleScreen.vue'),
    meta: {
      title: 'Schedule',
      requiresAuth: true
    }
  },
  {
    path: '/earnings',
    name: 'earnings',
    component: () => import('../views/Earnings/EarningsScreen.vue'),
    meta: {
      title: 'Earnings',
      requiresAuth: true,
      requiredCleaner: true
    }
  },
  {
    path: '/table',
    name: 'table',
    component: () => import('../views/Table/TableView.vue'),
    meta: {
      title: 'Table',
      requiresAuth: true
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/Settings/SettingsView.vue'),
    meta: {
      title: 'Settings',
      requiresAuth: true,
      requiredCleaner: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Settings/ProfileView.vue'),
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/checklists',
    name: 'checklists',
    component: () => import('../views/CheckList/ChecklistList.vue'),
    meta: {
      title: 'Checklists',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/checklist-location',
    name: 'checklist-location',
    component: () => import('../views/CheckList/ChecklistLocations.vue'),
    meta: {
      title: 'Checklist Locations',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/checklist-task',
    name: 'checklist-task',
    component: () => import('../views/CheckList/ChecklistTasks.vue'),
    meta: {
      title: 'Checklist Tasks',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/send-offer',
    name: 'send-offer',
    component: () => import('../views/Dashboard/SendOffer.vue'),
    meta: {
      title: 'Send Offer',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/booknow',
    name: 'booknow',
    component: () => import('../views/Order/OrderCreate/OrderCreateMain.vue'),
    meta: {
      title: 'Book Now',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/addresses',
    name: 'addresses',
    component: () => import('../views/Address/AddressList.vue'),
    meta: {
      title: 'My Addresses',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('../views/Address/EditAddress.vue'),
    meta: {
      title: 'Address View',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/checklist',
    name: 'checklist',
    component: () => import('../views/CheckList/CheckList.vue'),
    meta: {
      title: 'My Addresses',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/add-payout',
    name: 'add_payout',
    component: () => import('../views/Earnings/AddPayout.vue'),
    meta: {
      title: 'Add Payout',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/payment_methods',
    name: 'payment_methods',
    component: () => import('../views/Earnings/PaymentMethods.vue'),
    meta: {
      title: 'Payment Methods',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/add-payment',
    name: 'add_payment',
    component: () => import('../views/Earnings/AddPayment.vue'),
    meta: {
      title: 'Add Payment',
      requiresAuth: true,
      requiredAdmin: true,
      requiredUser: true
    }
  },
  {
    path: '/test-payment',
    name: 'test_payment',
    component: () => import('../views/TestPayment.vue'),
    meta: {
      title: 'Test Payment',
      requiresAuth: true,
    }
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('../views/UnauthorizedView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('../views/NotFoundView.vue')
  }
]

export default routes
