<template>
  <div>
    <div class="" v-if="!loading">
      <!-- If any user -->
      <div v-if="loggedin">
        <LeftNavbar :user="user" />
        <router-view class="absolute mt-16 w-4/5 md:w-3/4 right-0 overflow-y-auto" />
      </div>
      <!-- If not logged in -->
      <div v-else>
        <!-- <TopNavbar /> -->
        <router-view class="" />
      </div>
    </div>
    <LoadingIcon :loading="loading" class="p-20" />
  </div>
</template>

<script lang="ts">
import { ref, onMounted, watch, reactive } from 'vue'
import LeftNavbar from '@/navigations/LeftNavbar.vue'
// import TopNavbar from '@/navigations/TopNavbar.vue'
import LoadingIcon from './components/LoadingIcon.vue'
import { useAuthStore } from '@/store/auth'
import { auth } from '@/services/firebase'
import User from './models/User'
import { getUserData } from '@/utils'

export default {
  name: 'App',
  components: {
    LeftNavbar,
    // TopNavbar,
    LoadingIcon
  },
  setup () {
    const authStore = useAuthStore()
    const isAuthenticated = authStore.isAuthenticated
    const loggedin = ref(isAuthenticated)
    const loading = ref(true)

    const fetchUserData = async () => {
      const currentUser = auth.currentUser
      if (currentUser) {
        const updatedUser = await getUserData(currentUser)
        authStore.setUser(updatedUser)
        loggedin.value = true
      } else {
        loggedin.value = false
      }
      loading.value = false
    }

    onMounted(async () => {
      await fetchUserData()
    })

    // listen to authStore.user
    watch(
      () => authStore.user,
      async (newUser: User) => {
        await fetchUserData()
      },
      { deep: true }
    )

    return {
      loggedin,
      loading
    }
  }
}
</script>
