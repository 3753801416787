import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  connectAuthEmulator
} from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import {
  getFunctions,
  connectFunctionsEmulator
} from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyB1KAmg6Oj_Om3Z1rN7NylGFSH0BZmS2LU",
    authDomain: "shine-hr.firebaseapp.com",
    projectId: "shine-hr",
    storageBucket: "shine-hr.appspot.com",
    messagingSenderId: "119792890887",
    appId: "1:119792890887:web:f0f3fc1009a405a59626a2",
    measurementId: "G-GRBE4JDNBY"
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth()
const firestore = getFirestore(firebaseApp)
const functions = getFunctions(firebaseApp);

const USE_EMULATOR = process.env.VUE_APP_USE_EMULATORS;
console.log ("use emulaor", USE_EMULATOR);
// Check if the app is running in a local environment
if (process.env.VUE_APP_USE_EMULATORS == 'true' && window.location.hostname === 'localhost') {
  // Use the Auth emulator
  connectAuthEmulator(auth, 'http://localhost:9099');
  // Use the Firestore emulator
  connectFirestoreEmulator(firestore, 'localhost', 5002);
  // Use the Functions emulator
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export {
  auth,
  firebaseApp,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  firestore,
  createUserWithEmailAndPassword,
  functions
}
