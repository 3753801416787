import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: ""
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftNavbar = _resolveComponent("LeftNavbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LoadingIcon = _resolveComponent("LoadingIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!$setup.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ($setup.loggedin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_LeftNavbar, { user: _ctx.user }, null, 8, ["user"]),
                _createVNode(_component_router_view, { class: "absolute mt-16 w-4/5 md:w-3/4 right-0 overflow-y-auto" })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_router_view, { class: "" })
              ]))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_LoadingIcon, {
      loading: $setup.loading,
      class: "p-20"
    }, null, 8, ["loading"])
  ]))
}