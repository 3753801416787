<template>
  <!-- Check if it is mobile view, if yes then display width 60 -->
  <div class="flex flex-col mr-2 md:mr-10 fixed right-0 top-0 mt-16 bg-background rounded-lg shadow-lg p-3 w-64 md:w-80"
    v-if="visible">
    <div class="flex justify-between mb-6">
      <h1 class="text-lg font-semibold text-custom-green">{{ $t('common.notifications') }}</h1>
      <div
        class="text-black hover:text-custom-green focus:outline-none font-medium rounded-lg mt-1 text-sm cursor-pointer"
        @click="readAll()" v-if="notifications.length > 0">
        {{ $t('common.mark_read') }}
      </div>
    </div>
    <LoadingIcon :loading="loading" />
    <!-- Containing a scrollable table of all the values in data -->
    <div v-if="!loading && notifications.length > 0" class="flex flex-col mt-2 mb-3 h-96 overflow-y-auto">
      <div v-for="(notification, index) in notifications" :key="index"
        class="rounded-lg shadow-lg p-2 flex flex-row items-center mt-2 cursor-pointer"
        :class="notification.status == 'unread' ? 'bg-custom-xlight-green' : 'bg-white'" @click="read(index)">
        <div class="flex mx-2 items-center">
          <img v-if="notification.status == 'read'" src="@/assets/icons/info.svg" alt="Icon" class="w-8 h-8 mr-2" />
          <img v-else src="@/assets/icons/info_unread.svg" alt="Icon" class="w-8 h-8 mr-2" />
          <span class="flex flex-col items-start">
            <h1 class="text-sm font-semibold">{{ notification.title }}</h1>
            <p class="text-sm text-gray-500">{{ notification.message }}</p>
          </span>
        </div>
      </div>
    </div>
    <!-- <NoRecordScreen :show="!loading && notifications.length == 0" /> -->
  </div>
</template>

<script lang="js">
import { ref, onMounted, watch } from 'vue';
import LoadingIcon from '@/components/LoadingIcon.vue';
// import { NotificationService } from '@/services/notification_service';
// import NoRecordScreen from '@/components/Screens/NoRecordScreen.vue';
// import { auth } from '@/services/firebase';
// import { useNotificationStore } from '@/store/notification.ts';

export default {
  name: 'NotificationComp',
  components: {
    LoadingIcon,
    // NoRecordScreen,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const loading = ref(true);
    const notifications = ref([]);
    // const notificationStore = useNotificationStore();

    const getAll = async () => {
      // notifications.value = [];
      // loading.value = true;
      // let user_id = auth.currentUser.uid;
      // let response = await (new NotificationService()).getAllNotificationsOfUser(1, auth.currentUser.uid);
      // notifications.value = response;
      // loading.value = false;
    };

    const readAll = async () => {
      // notifications.value.forEach(async (notification) => {
      //   notification.status = 'read'
      //   await (new NotificationService()).markAsRead(notification.id);
      // })
    };

    const read = async (index) => {
      // notifications.value[index].status = 'read'
      // await (new NotificationService()).markAsRead(notifications.value[index].id);
      // await getAll();
      // notificationStore.setCount(notifications.value.length);
    };

    onMounted(async () => {
      await getAll();
    });

    // watch(
    //   () => notificationStore.count,
    //   (newVal, oldVal) => {
    //     if (newVal == 0) return;
    //     if (newVal > oldVal) {
    //       getAll();
    //     }
    //   }
    // );

    return {
      loading,
      notifications,
      readAll,
      read,
      getAll,
    };
  },
};
</script>
